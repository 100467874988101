<template>
    <b-overlay
        id="overlay-stage"
        :show="modalIniciarAsistencia"
        :opacity="1"
        variant="transparent"
        blur="1rem"
        no-center
        class="container"
    >
        <div>
            <b-row class="py-2">
                <b-col>
                    <b-card>
                        <b-card-title>Información del paciente</b-card-title>
                        <b-card-body>
                            <b-row class="">
                                <b-col cols="auto">
                                    <b-avatar
                                        variant="success"
                                        size="lg"
                                        rounded
                                    />
                                </b-col>
                                <b-col>
                                    <h4>Gian Carlos Galán Sánchez</h4>
                                    <small>12 años</small>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <app-collapse type="margin">
                                        <app-collapse-item
                                            title="Datos personales"
                                        >
                                            <b-row>
                                                <b-col>
                                                    <div>
                                                        <span
                                                            class="font-weight-bold mr-1"
                                                            >Identificación:</span
                                                        >
                                                        <span
                                                            >CC:
                                                            1007193316</span
                                                        >
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="font-weight-bold mr-1"
                                                            >Fecha
                                                            nacimiento:</span
                                                        >
                                                        <span
                                                            >05 mayo 2001</span
                                                        >
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="font-weight-bold mr-1"
                                                            >Genero:</span
                                                        >
                                                        <span>Masculino</span>
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="font-weight-bold mr-1"
                                                            >Altura:</span
                                                        >
                                                        <span>1,75</span>
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="font-weight-bold mr-1"
                                                            >Genero:</span
                                                        >
                                                        <span>Masculino</span>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>

                                        <app-collapse-item title="Riesgos">
                                            <b-row>
                                                <b-col>
                                                    <div
                                                        v-for="(
                                                            riesgo, index
                                                        ) in riesgos"
                                                        :key="index"
                                                        class="my-1 mx-0"
                                                    >
                                                        <span class="mr-1">{{
                                                            riesgo.nombre
                                                        }}</span>
                                                        <b-badge
                                                            v-show="
                                                                riesgo.estado ==
                                                                0
                                                            "
                                                            variant="success"
                                                            pill
                                                            >Bajo</b-badge
                                                        >
                                                        <b-badge
                                                            v-show="
                                                                riesgo.estado ==
                                                                1
                                                            "
                                                            variant="warning"
                                                            pill
                                                            >Medio</b-badge
                                                        >
                                                        <b-badge
                                                            v-show="
                                                                riesgo.estado ==
                                                                2
                                                            "
                                                            variant="danger"
                                                            pill
                                                            >Grave</b-badge
                                                        >
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>
                                        <app-collapse-item title="Dispositivos">
                                            <b-row>
                                                <b-col>
                                                    <b-alert
                                                        show
                                                        variant="secondary"
                                                    >
                                                        <div class="alert-body">
                                                            <feather-icon
                                                                icon="InfoIcon"
                                                                class="mr-1"
                                                            ></feather-icon>
                                                            <small
                                                                >Los
                                                                dispositivos
                                                                usados en esta
                                                                asistenica
                                                                tendrán un
                                                                indicador a la
                                                                derecha.</small
                                                            >
                                                        </div>
                                                    </b-alert>

                                                    <div
                                                        v-for="(
                                                            dispositivo, index
                                                        ) in dispositivos"
                                                        :key="index"
                                                        class="my-1"
                                                    >
                                                        <b-row>
                                                            <b-col cols="auto">
                                                                <img
                                                                    :src="
                                                                        dispositivo.foto
                                                                    "
                                                                    alt="Foto del dispositivo"
                                                                    height="80"
                                                                    width="80"
                                                                    class="rounded"
                                                                />
                                                            </b-col>
                                                            <b-col>
                                                                <p
                                                                    class="font-weight-bold"
                                                                >
                                                                    {{
                                                                        dispositivo.nombre
                                                                    }}
                                                                </p>
                                                                <small
                                                                    >Tenencia
                                                                    desde 20
                                                                    julio
                                                                    2021</small
                                                                >
                                                            </b-col>
                                                            <b-col
                                                                cols="auto"
                                                                class="my-auto text-center"
                                                            >
                                                                <b-badge
                                                                    v-show="
                                                                        dispositivo.isActive
                                                                    "
                                                                    variant="success"
                                                                    pill
                                                                >
                                                                    <feather-icon
                                                                        icon="CheckIcon"
                                                                    ></feather-icon>
                                                                </b-badge>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>
                                    </app-collapse>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card>
                        <b-card-title
                            >Información de la asistencia</b-card-title
                        >
                        <b-row
                            :class="
                                showContador
                                    ? 'justify-content-between'
                                    : 'justify-content-end'
                            "
                        >
                            <b-col>
                                <small v-if="showContador">
                                    Tiempo para iniciar la asistencia
                                </small>
                            </b-col>
                            <b-col cols="auto">
                                <b-dropdown
                                    variant="flat-primary"
                                    size="sm"
                                    right
                                    no-caret
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                        ></feather-icon>
                                    </template>
                                    <b-dropdown-item
                                        @click="modalIniciarAsistencia = true"
                                        >Iniciar inmediatamente</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        >Reprogramar</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        @click="abrirModalCancelarAsistencia"
                                        >Cancelar</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-col>
                        </b-row>

                        <b-row v-if="showContador">
                            <b-col>
                                <p>Días</p>
                                <b-card bg-variant="light" class="text-center">
                                    <span>{{ days % 365 }}</span>
                                </b-card>
                            </b-col>
                            <b-col>
                                <p>Horas</p>
                                <b-card bg-variant="light" class="text-center">
                                    <span>{{ hours % 24 }}</span>
                                </b-card>
                            </b-col>
                            <b-col>
                                <p>Minutos</p>
                                <b-card bg-variant="light" class="text-center">
                                    <span>{{ minutes % 60 }} </span>
                                </b-card>
                            </b-col>
                            <b-col>
                                <p>Segundos</p>
                                <b-card bg-variant="light" class="text-center">
                                    <span>{{ seconds % 60 }} </span>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col class="text-center">
                                <b-button
                                    variant="success"
                                    pill
                                    class="px-75 animate__animated animate__rubberBand"
                                    v-b-tooltip.hover.top="
                                        'Iniciar la asistencia  '
                                    "
                                    @click="modalIniciarAsistencia = true"
                                >
                                    <feather-icon
                                        icon="PowerIcon"
                                        size="50"
                                    ></feather-icon>
                                </b-button>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-row class="d-flex align-items-center mt-1">
                                    <b-col cols="auto" class="pr-0">
                                        <div
                                            class="bg-light-secondary rounded p-1"
                                        >
                                            <feather-icon
                                                icon="CalendarIcon"
                                                size="20"
                                            ></feather-icon>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <p class="my-0 font-weight-bold">
                                            {{
                                                lunchDate.format("DD MMM yyyy")
                                            }}
                                        </p>
                                        <p class="my-0">
                                            {{ lunchDate.format("hh:mm a") }}
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row class="d-flex align-items-center mt-1">
                                    <b-col cols="auto" class="pr-0">
                                        <div
                                            class="bg-light-secondary rounded p-1"
                                        >
                                            <feather-icon
                                                icon="UserIcon"
                                                size="20"
                                            ></feather-icon>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <p class="my-0 font-weight-bold">
                                            Paciente
                                        </p>
                                        <p class="my-0">Gian Carlos</p>
                                    </b-col>
                                </b-row>
                                <b-row class="d-flex align-items-center mt-1">
                                    <b-col cols="auto" class="pr-0">
                                        <div
                                            class="bg-light-secondary rounded p-1"
                                        >
                                            <feather-icon
                                                icon="UserCheckIcon"
                                                size="20"
                                            ></feather-icon>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <p class="my-0 font-weight-bold">
                                            Doctor
                                        </p>
                                        <p class="my-0">Oscar Acelas</p>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col>
                                        <b-row>
                                            <b-col
                                                cols="auto"
                                                class="bg-light-secondary rounded d-flex align-items-center ml-1 px-0"
                                            >
                                                <div class="p-1">
                                                    <feather-icon
                                                        icon="MessageSquareIcon"
                                                        size="20"
                                                    ></feather-icon>
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <p
                                                    class="font-weight-bold pl-0"
                                                >
                                                    Caso presentado
                                                </p>
                                                <p>
                                                    Constante dolor de cabeza,
                                                    sueño, falta de
                                                    concentración, cansancio
                                                    crónico.
                                                </p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <template v-slot:overlay>
            <div class="text-center p-3 mt-5">
                <p>
                    <strong id="form-confirm-label"
                        >¿Deseas iniciar la asistencia?</strong
                    >
                </p>
                <div class="d-flex justify-content-center">
                    <b-button
                        variant="outline-danger"
                        class="mr-3"
                        @click="modalIniciarAsistencia = false"
                    >
                        No
                    </b-button>
                    <b-button
                        variant="outline-success"
                        @click="iniciarAsistencia"
                    >
                        Sí
                    </b-button>
                </div>
            </div>
        </template>
        <!-- modales -->
        <modal-general
            id="cancelar-asistencia"
            ref="modalCancelarAsistencia"
            text="¿Deseas cancelar esta asistencia?"
            title="Cancelar asistencia"
        >
        </modal-general>
    </b-overlay>
</template>
<script>
import moment from "moment";
import { ref, onMounted } from "@vue/composition-api";
export default {
    emits: ["iniciarAsistencia"],
    components: {
        AppCollapse: () =>
            import("@core/components/app-collapse/AppCollapse.vue"),
        AppCollapseItem: () =>
            import("@core/components/app-collapse/AppCollapseItem.vue"),
    },
    setup(props, context) {
        const riesgos = ref([
            {
                nombre: "Tuberculosis",
                estado: 1,
            },
            {
                nombre: "Fátiga crónica",
                estado: 0,
            },
            {
                nombre: "Artritis",
                estado: 2,
            },
        ]);

        const dispositivos = ref([
            {
                foto: "/img/dispositivos/blood_pressure.jpg",
                nombre: "Blood pressure",
                isActive: false,
            },
            {
                foto: "/img/dispositivos/body_composition.jpg",
                nombre: "Body composition",
                isActive: true,
            },
            {
                foto: "/img/dispositivos/weight_scale.jpeg",
                nombre: "Weight scale",
                isActive: false,
            },
        ]);

        const modalIniciarAsistencia = ref(false);

        // para el contador

        const showContador = ref(true);

        const days = ref(0);
        const hours = ref(0);
        const minutes = ref(0);
        const seconds = ref(0);
        const lunchDate = moment().add(10, "s"); // aquí va la fecha de la asistencia

        var interval = setInterval(() => {
            const currentDate = new Date();
            const lunchTime = lunchDate - currentDate;
            seconds.value = parseInt(lunchTime / 1000);
            minutes.value = parseInt(seconds.value / 60);
            hours.value = parseInt(minutes.value / 60);
            days.value = parseInt(hours.value / 24);
            if (
                !days.value &&
                !hours.value &&
                !minutes.value &&
                !seconds.value
            ) {
                showContador.value = false;
                clearInterval(interval);
            }
        }, 1000);

        function iniciarAsistencia() {
            context.emit("iniciarAsistencia");
        }

        function abrirModalCancelarAsistencia() {
            context.refs.modalCancelarAsistencia.toggle();
        }

        return {
            riesgos,
            dispositivos,
            showContador,
            days,
            hours,
            minutes,
            seconds,
            lunchDate,
            modalIniciarAsistencia,
            iniciarAsistencia,
            abrirModalCancelarAsistencia,
        };
    },
};
</script>
